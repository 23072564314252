import React from "react";
import clsx from "clsx";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";

import { ISidebar } from "./Sidebar";
import {
  Dashboard,
  HistoryIcon,
  Recipients,
  Rewards,
  Support,
  Logout,
  UserPhoto,
  MoneyHopLogoWhite,
  CloseIcon,
  PrimeTag,
} from "../../../assets/Icons";
import Button from "../Button";
import Typograpy from "../Typography";
import { AnimatedButton } from "../AnimatedButton";
import TermsModal from "../../../pages/auth/SignUp/TermsModal";
import { openSidebar, transactionDetails, userAdditionalDocs } from "../../../store";
import { clearTransactionStates } from "../../../services/transactionDetails/clearTransactionStatus";
import { keysToRemove } from "../../../constants/keysToRemove";

export const Sidebar: React.FunctionComponent<ISidebar.SidebarProps> = ({
  logo = <MoneyHopLogoWhite />,
  // handleLogoutClick,
  buttonList,
  userName,
  closeSidebar,
  showSidebar,
  isPrime,
}) => {
  const linkClass =
    "sans-serif text-[16px] font-normal cursor:pointer  text-gray-200 no-underline hover:translate-y-[2px]";

  const navigate = useNavigate();
  const [isTermsModalOpen, setIsTermsModalOpen] = React.useState<boolean>(false);
  const [modalTitle, setModalTitle] = React.useState<string>("");
  const setOpenSidebars = useSetRecoilState(openSidebar);
  const resetTransactionData = useResetRecoilState(transactionDetails);
  const resetUserAdditionalDocs = useResetRecoilState(userAdditionalDocs);
  const mixpanelToken: any = process.env.REACT_APP_MIXPANEL_TOKEN;
  mixpanel.init(mixpanelToken, {
    debug: true,
    api_host: process.env.REACT_APP_MIXPANEL_API,
  });
  const handleLogout = () => {
    window.console.log("Clearing session storage and logging out...");
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.setItem("isLoggedInState", JSON.stringify(false));
  
    resetTransactionData();
    resetUserAdditionalDocs();
    clearTransactionStates(keysToRemove);
  
    setOpenSidebars(false);
  
    mixpanel.track("Logout");
    mixpanel.reset();
  
    navigate("/auth/sign-in", { replace: true });
  };

  /* Side bar buttons */
  const defaultButtonList = [
    {
      defaultBtnIcon: <Dashboard />,
      defaultBtnText: "Dashboard",
      mixpanelTrack: "Dashboard_navigation",
      pathName: "/",
    },
    {
      defaultBtnIcon: <Recipients />,
      defaultBtnText: "Recipients",
      mixpanelTrack: "Recipient_navigation",
      pathName: "/recipients",
    },
    {
      defaultBtnIcon: <HistoryIcon />,
      defaultBtnText: "History",
      mixpanelTrack: "History_navigation",
      pathName: "/history",
    },
    {
      defaultBtnIcon: <Support />,
      defaultBtnText: "Support",
      mixpanelTrack: "Support_navigation",
      pathName: "/support",
    },
    {
      defaultBtnIcon: <Rewards />,
      defaultBtnText: "Rewards",
      mixpanelTrack: "Rewards_navigation",
      pathName: "/rewards",
    },
  ];

  return (
    <div className="bg-primary md:w-[370px] w-[307px] fixed sm:relative !overflow-auto md:overflow-hidden xs:overflow-y-scroll h-full">
      <div className="flex flex-col content-center justify-center">
        <div className="mt-[28px] md:mt-[49px] flex justify-center gap-[29px]">
          {showSidebar && (
            <div className="z-50 flex items-center justify-center" onClick={closeSidebar}>
              <CloseIcon className="w-6 h-6" />
            </div>
          )}
          <div className="flex flex-col items-center">
            <div className="cursor-pointer" onClick={() => navigate("/")}>
              {logo}
            </div>
          </div>

        </div>
        <div
          id="sidebar"
          className="flex flex-col items-center justify-center md:flex-col md:items-center md:justify-center md:ml-2 mt-[46px] md:mt-0 mb-5 md:mb-0 gap-4"
        >
          <div id="profile" className="flex flex-col items-center justify-center space-x-5">
            <div className="justify-center md:mt-[29px] w-[65px]">
              <div
                onClick={() => {
                  navigate("/profile");
                  mixpanel.track("Profile Clicked");
                }}
                className="cursor-pointer"
              >
                <UserPhoto fillColor="hover:scale-125 duration-400 transition-all" />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center md:mt-[11px] text-center">
              <Typograpy
                fontStyle={{
                  fontFamily: "font-sans",
                  fontSize: "lg:text-[20px] text-[18px]",
                  fontWeight: "font-bold",
                  fontColor: "text-white",
                }}
                className="-ml-3"
                tagType={"span"}
              >
                Welcome
              </Typograpy>
              <Typograpy
                fontStyle={{
                  fontFamily: "font-sans",
                  fontSize: "text-[17px]",
                  fontWeight: "font-semibold",
                  fontColor: "text-white",
                }}
                tagType={"symbol"}
                className="-ml-3"
              >
                {userName}
              </Typograpy>
            </div>
          </div>
          {isPrime ? (
            <div
              onClick={() => {
                navigate("/hopprime");
                // window.open("https://moneyhop.co/hopprime");
                mixpanel.track("Become Prime Clicked");
              }}
              className="group w-4/6 hover:shadow-xl hover:shadow-yellow-500 shadow-xl shadow-blue-800 cursor-pointer hover:w-5/6 transition-all mt-4 lg:mt-8 mb-2 px-5 py-2 bg-[#FFE092] flex space-x-2 rounded-lg items-center justify-center"
            >
              <div className="transition-all group-hover:scale-105">
                <PrimeTag />
              </div>
              <span className="text-[#4B3D17] transition-all font-semibold font-ssp text-[14px] group-hover:text-[19px] select-none">
                Prime Member
              </span>
            </div>
          ) : (
            <div
              onClick={() => {
                navigate("/hopprime");
                // window.open("https://moneyhop.co/hopprime");
                mixpanel.track("Become Prime Clicked");
              }}
              className="group w-4/6 hover:shadow-xl hover:shadow-yellow-500 shadow-xl shadow-blue-800 cursor-pointer hover:w-5/6 transition-all mt-4 lg:mt-8 mb-2 px-5 py-2 bg-[#FFE092] flex space-x-2 rounded-lg items-center justify-center"
            >
              <span className="text-[#4B3D17] transition-all flex justify-center font-semibold font-ssp text-[10px] group-hover:text-[13px] select-none">
                Become a prime member!
              </span>
            </div>
          )}
        </div>
        <div className="flex flex-col items-center mt-[10px] lg:mt-[25px] z-0">
          <div id="sendMoney" className="hover:shadow-xl hover:shadow-primary-650">
            <AnimatedButton
              onClick={() => {
                navigate("/send-money");
                mixpanel.track("Send money_navigation");
              }}
              text="Send Money"
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center transition-all">
          {buttonList
            ? buttonList.map((item, index) => (
              <div id={item.btnTitle === "History" ? "history" : ""} key={index}>
                <Button
                  id={item.btnTitle}
                  key={index}
                  bgColor={"bg-primary"}
                  fontStyle={{
                    fontFamily: "font-sans",
                    fontSize: "text-[16px]",
                    fontColor: "text-white",
                  }}
                  onClickHandler={() => item.btnhandleClick}
                  className={
                    "mt-[5px] active:bg-primary-300 hover:bg-primary-300 transition-all rounded w-[220px] h-[45px]"
                  }
                >
                  <div className="flex flex-row items-center justify-center transition-all">
                    {item.btnIcon}
                    <Typograpy
                      fontStyle={{
                        fontFamily: "font-sans",
                        fontSize: "text-[16px]",
                        fontWeight: "font-semibold",
                        fontColor: "text-white",
                      }}
                      className="ml-3"
                      tagType={"span"}
                    >
                      {item.btnTitle}
                    </Typograpy>
                  </div>
                </Button>
              </div>
            ))
            : defaultButtonList.map((element, idx) => (
              <div key={idx} id={element.defaultBtnText === "History" ? "history" : ""}>
                <Button
                  id={element.pathName}
                  bgColor={"bg-primary"}
                  fontStyle={{
                    fontFamily: "font-sans",
                    fontSize: "text-[16px]",
                    fontWeight: "fony-normal",
                    fontColor: "text-white",
                  }}
                  onClickHandler={() => {
                    navigate(element.pathName, {
                      state: {
                        fromSendMoney: false,
                      },
                    });
                    mixpanel.track(element.mixpanelTrack);
                  }}
                  className={clsx(
                    element.pathName === window.location?.pathname &&
                    "bg-primary-300 hover:bg-primary-300",
                    "mt-[21px] active:  rounded w-[220px] h-[45px]",
                  )}
                >
                  <div className="flex flex-row items-center justify-start w-4/6 transition-all">
                    <div className="mr-[17px]">{element.defaultBtnIcon}</div>
                    <Typograpy
                      fontStyle={{
                        fontFamily: "font-sans",
                        fontSize: "text-[16px]",
                        fontWeight: "font-semibold",
                        fontColor: "text-white",
                      }}
                      className="ml-3"
                      tagType={"span"}
                    >
                      {element.defaultBtnText}
                    </Typograpy>
                  </div>
                </Button>
              </div>
            ))}

          <div className="h-[2px] mt-[28px]  :mt-[48px] w-[233px] border-[1px] border-primary-300" />
          <div className="sticky object-bottom-2">
            <div className="mt-[25px] md:mt-[32px] flex flex-col items-center justify-center">
              <div
                onClick={() => {
                  setIsTermsModalOpen(true), setModalTitle("Terms & Conditions");
                  mixpanel.track("T&C_navigation");
                }}
                className={clsx(linkClass, "cursor-pointer")}
              >
                Terms & Conditions
              </div>
              <div
                onClick={() => {
                  setIsTermsModalOpen(true), setModalTitle("Privacy & Policy");
                  mixpanel.track("PrivacyPolicy_navigation");
                }}
                className={clsx(linkClass, "cursor-pointer mt-[18px] md:mt-[24px]")}
              >
                Privacy Policy
              </div>
              {/* <div
                onClick={() => {
                  navigate("/bug-report");
                  mixpanel.track("Report bug");
                }}
                className={clsx(linkClass, "cursor-pointer mt-[20px] md:mt-[24px]")}
              >
                Report bug
              </div> */}
            </div>
            <div>
              <Button
                id="log-out-btn"
                bgColor={"bg-primary"}
                fontStyle={{
                  fontFamily: "font-sans",
                  fontSize: "text-[16px]",
                  fontWeight: "font-normal",
                  fontColor: "text-white",
                }}
                borderStyle={{
                  borderColor: undefined,
                  borderWidth: undefined,
                  borderRadius: "",
                }}
                onClickHandler={handleLogout}
                className={
                  "mt-[36px] md:mt-[36px] border-2 border-white-100 hover:bg-primary-400 rounded w-[220px] h-[45px] mb-8"
                }
              >
                <div className="flex flex-row items-center justify-center">
                  <Logout />
                  <Typograpy
                    fontStyle={{
                      fontFamily: "",
                      fontSize: "text-[14px]",
                      fontWeight: "font-semibold",
                      fontColor: "",
                    }}
                    className="ml-3"
                    tagType={"span"}
                  >
                    Log Out
                  </Typograpy>
                </div>
              </Button>
            </div>
            {/* <div className={`flex flex-col items-center gap-6 my-3 mb-6`}>
              <div
                className={`ml-[-3%] cursor-pointer w-44 h-12`}
                onClick={() => {
                  window.open(appLinks?.playSotre, "_blank");
                }}

              >
                <img src={playStore} alt="" />
              </div>
              <div
                className={`ml-[-3%] cursor-pointer w-44 h-12`}
                onClick={() => {
                  window.open(appLinks?.appleStore, "_blank");
                }}
              >
                <img src={appStore} alt="" />
              </div>
            </div> */}
          </div>
          <TermsModal
            isTermsModalOpen={isTermsModalOpen}
            setIsTermsModalOpen={setIsTermsModalOpen}
            modalTitle={modalTitle}
          />
        </div>
      </div>
    </div>
  );
};
