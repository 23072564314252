/* eslint-disable */
import React, { FC } from "react";
import { Typography } from "@mui/material";
import FloatingDropDown from "../../components/ui/FloatingDropDown";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const OtherDetails: FC<UserDetailsProps.OtherDetailsProps> = ({
  // sourceOfFunds,
  employmentStatus,
  industryType,
  onSelectEmploymentStatus,
  onSelectIndustryType,
  // onSelectSourceOfFunds,
}) => {
  return (
    <div className="mt-5 w-full md:flex justify-center">
      <div>
        <Typography className="text-primary">Other Details</Typography>

        <div className="grid xs:grid-cols-1 xs:gap-x-24 xs:gap-y-5 xl:grid-cols-3 md:grid-cols-2 md:gap-x-24 md:gap-y-5 md:space-x-0 pace-y-5 xl:space-y-0 xl:gap-32 my-5">
          {/* <FloatingDropDown
            options={sourceOfFunds}
            onSelect={(e) => onSelectSourceOfFunds(e)}
            placeHolder="Source of funds"
            required
            dropdownClassname="md:w-96 w-full"
          /> */}
          <FloatingDropDown
            options={employmentStatus}
            onSelect={(e) => onSelectEmploymentStatus(e)}
            placeHolder="Employment Status"
            required
            dropdownClassname="md:w-96 w-full"
          />
          <FloatingDropDown
            options={industryType}
            onSelect={(e) => onSelectIndustryType(e)}
            placeHolder="Industry Type"
            required
            dropdownClassname="md:w-96 w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default OtherDetails;
