//Global Imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
//End Global Imports

//Local Imports
import { isAuthorised, refreshToken } from "../services/auth";
import ProtectedPageLayout from "../components/layout/ProtectedPageLayout";
import { userDetails } from "../store";
import mixpanel from "mixpanel-browser";
import Cookies from "universal-cookie";
import { useIdleTimer } from "react-idle-timer";
import { clearTransactionStates } from "../services/transactionDetails/clearTransactionStatus";
import { keysToRemove } from "../constants/keysToRemove";
//End Local Imports

const useTokenRefresh = (idleTime: any) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isActive) {
      const interval = setInterval(() => {
        refreshToken()
          .then((response: any) => {
            if (response?.data) {
              sessionStorage.setItem("idToken", response?.data?.token);
            }
          })
          .catch((err) => {
            window.console.error(err);
          });
      }, idleTime);

      return () => clearInterval(interval);
    }
  }, [isActive, idleTime]);

  const signalActivity = useCallback(() => {
    setIsActive(true);
  }, []);
  return { signalActivity };
};

const PrivateRoute = () => {
  const navigate = useNavigate();
  const idleTime = 1000 * 60 * 30; // 30 minutes in milliseconds
  const { signalActivity } = useTokenRefresh(idleTime);
  const idleTimerRef = useRef(null);

  const handleOnIdle = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    clearTransactionStates(keysToRemove);
    mixpanel.track("Logout");
    mixpanel.reset();
    new Cookies().remove("XSRF-TOKEN");
    navigate("/auth/sign-in", { replace: true });
  }, [navigate]);

  const currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "{}");
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hopCoins, setHopCoins] = useState<number>(0);
  const [zoom, setZoom] = useState("100%");
  const setUserDetails = useRecoilState(userDetails)[1];

  const updateZoom = () => {
    if (window.innerWidth >= 1024) {
      setZoom("80%");
    } else {
      setZoom("60%");
    }
  };

  useIdleTimer({
    ref: idleTimerRef,
    timeout: idleTime,
    onIdle: handleOnIdle,
    onActive: signalActivity,
    onAction: signalActivity,
    debounce: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  useEffect(() => {
    updateZoom();
    window.addEventListener("resize", updateZoom);

    return () => {
      window.removeEventListener("resize", updateZoom);
    };
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setHopCoins(0);
        if (!currentUser || Object.keys(currentUser).length === 0) {
          throw new Error("User not authenticated");
        }

        const response = await isAuthorised(currentUser);

        if (response?.data?.success === false) {
          setIsLoggedIn(false);
          navigate("/auth/sign-in", { replace: true });
          throw new Error(response?.data?.error?.type || "Authorization failed");
        }

        setIsLoggedIn(true);
        setUserDetails(response?.data);
        setHopCoins(response?.data?.active_hop_coins || 0);
      } catch (error) {
        window.console.error("Error in PrivateRoute:", error);
        setIsLoggedIn(false);
        navigate("/auth/sign-in", { replace: true });
      } finally {
        setIsLoading(false);
      }
    };

    if (isLoading) {
      fetchUserDetails();
    }
  }, [currentUser, isLoading, navigate, setUserDetails]);

  if (isLoading) {
    return null; // Or a loading spinner
  }

  return isLoggedIn ? (
    <div className="h-full">
      <ProtectedPageLayout hopCoins={hopCoins}>
        <div style={{ zoom }} className="h-full">
          <Outlet />
        </div>
      </ProtectedPageLayout>
    </div>
  ) : (
    <Navigate to="/auth/sign-in" replace />
  );
};


export default PrivateRoute;
